import React from 'react';
import Navbar from 'react-bootstrap/Navbar';

const Nav = () => {
    return (
        <Navbar bg="dark" variant="dark" fixed="top">
            <Navbar.Brand>US County COVID-19 Tracker</Navbar.Brand>
        </Navbar>
    )
}

export default Nav;
